export const routes = [
    {
        path: '/clock_ins',
        name: 'clock_ins.browse',
        component: () => import(/* webpackChunkName: "ClockInsBrowse" */ '@/views/app/ClockInsBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/clock_ins/create',
        name: 'clock_ins.create',
        component: () => import(/* webpackChunkName: "ClockInsActions" */ '@/views/app/ClockInsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/clock_ins/:id/edit',
        name: 'clock_ins.edit',
        component: () => import(/* webpackChunkName: "ClockInsActions" */ '@/views/app/ClockInsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/clock_ins/:id/delete',
        name: 'clock_ins.delete',
        component: () => import(/* webpackChunkName: "ClockInsActions" */ '@/views/app/ClockInsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]